.header {
  display: grid;
  grid-template-areas:
    'back branding actions'
    'back user-info actions';
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto;
  padding: 12px;

  @media all and (min-width: 992px) {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding: 0;
  }

  &__logo {
    margin-top: 3px;

    @media all and (min-width: 992px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__branding {
    display: flex;
    text-decoration: none;
    grid-area: branding;
    gap: 8px;

    @media all and (min-width: 992px) {
      gap: 16px;
    }

    span {
      display: flex;

      .header__logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 8px;
      }

      &.show-mobile {
        display: flex;
        justify-content: center;
        flex-direction: column;

        @media all and (min-width: 992px) {
          display: none;
        }
      }
    }
  }

  &__short-title {
    display: block;
    margin-bottom: 0;

    @media all and (min-width: 992px) {
      display: none;
    }
  }

  &__title {
    display: none;
    text-decoration: none;

    @media all and (min-width: 992px) {
      display: block;
      margin-bottom: 0;
    }
  }

  &__user-info {
    display: flex;
    grid-area: user-info;
    gap: 8px;
    flex-direction: column;

    @media all and (min-width: 992px) {
      margin-right: 60px;
      margin-left: auto;
      gap: 24px;
      flex-direction: row;
    }

    .operator-logos {
      display: none;

      @media all and (min-width: 992px) {
        display: block;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    grid-area: actions;
    gap: 8px;

    @media all and (min-width: 992px) {
      gap: 16px;
    }

    .search-button {
      @media all and (min-width: 992px) {
        display: none;
      }
    }
  }

  &__branch-logo {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      display: flex;
    }
  }

  &__user {
    font-family: var(--content);
    font-size: var(--font-small);
  }
}
