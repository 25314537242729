.monet-modal {
  .react-datepicker__input-container {
    display: flex;
    flex-direction: row;

    input {
      flex-grow: 1;
      font-size: 16px;
      color: var(--black);
      border: 0;
      padding: 0 24px;
      height: 55px;
      border-radius: 7px;

      &:focus {
        outline: none;
      }
    }
  }

  &__overlay.button {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transition: all 0.2s;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  &__button {
    font-size: var(--font-small);
    min-height: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 24px 16px;
    border-bottom: 3px solid var(--light-grey);
  }

  &__prefix {
    margin-right: 8px;

    > * {
      margin-right: 0;
    }
  }

  &__chapter {
    font-size: var(--font-medium);
  }

  &__close {
    margin-left: auto;
  }

  &__content {
    padding: 16px 16px 24px;
  }

  &__question {
    font-weight: bold;
    display: block;
  }

  &__assessments {
    display: flex;
    gap: 12px;
  }

  &__textarea {
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
    width: 100%;
    min-height: 100px;
    padding: 12px 16px;
    resize: none;
    border: 0;
    border-radius: 7px;
  }

  &__footer {
    padding: 24px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    background: var(--light-purple);
  }

  &__actions {
    display: flex;
    justify-content: space-between;
  }

  &__action {
    padding-right: 24px;
    padding-left: 24px;
  }

  &__files {
    > ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        margin-bottom: 10px;
        width: 100%;
        gap: 12px;
        border-radius: 8px;
        justify-content: space-between;

        .monet-modal__file-name {
          padding: 4px 4px 4px 12px;
        }

        .monet-modal__file-action {
          width: 48px;
          padding: 4px;
        }

        span {
          word-break: break-all;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          justify-content: center;
        }

        &:nth-child(odd) {
          background-color: white;
        }
      }
    }
  }
}

.file-upload-error {
  &__title {
    color: red;
  }

  &__file {
    font-weight: 600;
    word-break: break-all;
  }

  &__list {
    margin: 0;
    padding: 0 0 0 20px;
  }
}
