.label {
  font-family: var(--content);
  font-size: var(--font-medium);
  font-weight: bold;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  min-height: 13px;
  margin-right: 12px;
  padding: 4px 32px;
  white-space: nowrap;
  color: var(--white);
  border: 0;
  border-radius: 30px;
  background: var(--darker-grey);
  box-shadow: none;

  // @todo Add missing colors.
  &--nee {
    background: var(--black);
  }

  &--ja {
    background: var(--orange);
  }
}
