.data-table {
  box-sizing: border-box;
  width: 100%;
  padding: 30px;
  border-radius: 20px;
  background: var(--light-purple);

  .cursor-pointer {
    cursor: pointer;
  }

  .select-none {
    user-select: none;
  }

  .hidden__column {
    display: none;
  }

  &__table {
    width: 100%;
    border-spacing: 0;
  }

  &__table-header {
    font-weight: normal;
    padding: 8px 16px 32px;
    text-align: left;

    .sorting-icon {
      margin-left: 4px;
    }

    .sorting-icon-potential {
      opacity: 0.2;
    }

    &--table-commands + .sorting-icon {
      display: none;
    }
  }

  &__table-cell {
    padding: 24px 16px;
    color: var(--lavender);

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .table-cell-value {
      display: inline-flex;
      flex-direction: column;
      word-break: break-word;

      &::after {
        content: attr(data-text);
        content: attr(data-text) / '';
        height: 0;
        visibility: hidden;
        overflow: hidden;
        user-select: none;
        pointer-events: none;
        font-weight: bold;

        @media speech {
          display: none;
        }
      }
    }
  }

  &__table-row {
    &:nth-child(odd).data-table__table-row {
      background: var(--white);
    }

    &--header {
      background-color: transparent !important;
    }

    &--clickable {
      cursor: pointer;

      &:hover {
        background: #eeeefd !important;
        font-weight: bold;
      }
    }
  }

  &__pager {
    display: flex;
    justify-content: flex-start;
    gap: 24px;
  }

  &__pager-container {
    display: flex;
    margin-top: 12px;
    justify-content: flex-end;
  }

  &__pager-actions {
    display: flex;
    gap: 24px;
  }

  &__results {
    line-height: 37px;
  }

  &__page-size {
    display: flex;
    flex-direction: row;
    gap: 8px;

    span {
      line-height: 37px;
    }
  }
}
