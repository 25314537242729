.input-with-icon {
  display: flex;
  justify-content: flex-start;
  background-color: white;
  border-radius: 7px;

  &__icon-container {
    display: flex;
    width: 28px;
    flex-direction: column;
    padding-left: 24px;
    justify-content: center;
  }

  &__field-container {
    flex-grow: 2;
  }
}
