.monet-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: all;

  &__wrapper {
    position: fixed;
    z-index: 110;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 345px;
    transition: all 0.3s ease;
    transform: translate(-50%, -50%);
    transform-origin: center;
    border-radius: 7px;
    background: var(--white);

    @media all and (min-width: 992px) {
      width: 75%;
      max-width: 600px;
      min-width: 350px;
    }
  }

  &--error {
    .monet-modal__wrapper {
      z-index: 151;
    }

    z-index: 150;
  }

  &__content {
    background: var(--light-purple);
    padding: 16px 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 70vh;
    overflow-y: auto;

    label {
      span {
        display: inline-block;
        margin: 0 0 4px 12px;
      }
    }
  }

  &__overlay {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transition: all 0.2s;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  &__button {
    font-size: var(--font-small);
    min-height: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 24px 16px;
    border-bottom: 3px solid var(--light-grey);
  }

  &__prefix {
    margin-right: 8px;

    > * {
      margin-right: 0;
    }
  }

  &__error-container {
    color: var(--red);
    margin: 0;
    padding-left: 20px;
  }

  &__chapter {
    font-size: var(--font-medium);
  }

  &__close {
    margin-left: auto;
  }

  &__question {
    font-weight: bold;
    display: block;
    margin-bottom: 16px;
  }

  &__footer {
    padding: 24px;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    background-color: var(--light-purple);

    &--single-button {
      justify-content: flex-end;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
  }

  &__action {
    padding-right: 24px;
    padding-left: 24px;
  }
}
