.rie-detail-pdf {
  &__content {
    display: block;
  }

  &__info-blocks {
    display: flex;
  }

  &__info-block {
    margin-bottom: 16px;
    margin-right: 16px;
    margin-left: 0;
    width: 100%;

    .info-block__item {
      font-size: var(--font-small);
      padding-left: 20px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__accordion {
    margin-bottom: 8px;

    .accordion__toggle {
      display: none;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .question__header {
      page-break-inside: avoid;
    }

    .question__description {
      page-break-inside: avoid;
    }

    .accordion__header {
      page-break-inside: avoid;
    }

    .rie-detail__image-container--pdf {
      display: block;
    }

    .question__image-container {
      text-align: left;

      &--pdf {
        display: block;
        line-height: 0;
        padding-left: 35px;
      }
    }

    .rie-photo {
      page-break-inside: avoid;
      display: inline-block;

      img {
        margin: 0 4px 4px 0;
        height: 280px;
      }
    }
  }
}

.safety-status-symbol-wrapper {
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }
}
