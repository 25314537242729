h3,
.h3 {
  font-size: var(--font-title);
  font-weight: normal;
  margin-top: 0;
  color: var(--orange);
}

.map {
  width: 100%;
  height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &__google {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: unset;

    @media all and (min-width: 992px) {
      border-radius: 20px;
    }
  }
}

.my-location-button {
  position: absolute;
  right: 10px;
  top: 24px;
  background-color: white;
  border: none;
  border-radius: 2px;
  z-index: 10;
  padding: 4px;
  width: 40px;
  height: 40px;
}

.marker-details {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  color: var(--dark-grey);
  border-radius: 20px;
  background-color: var(--white);

  &__header {
    margin: 24px 42px 0;
    display: flex;
    justify-content: space-between;
  }

  &__close {
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__row {
    display: flex;
    padding: 12px 42px;
    color: var(--darker-grey);
    border-bottom: 1px solid var(--light-grey);

    &--actions {
      padding: 24px 42px;
    }

    &:last-child {
      border-bottom: 0;
    }

    span:first-child {
      font-weight: 600;
      flex: 0 0 50%;
    }
  }

  &__container {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 24px;
  }

  &--cluster {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    color: var(--dark-grey);
    border-radius: 20px;
    background-color: var(--white);

    .marker-details__row {
      span:first-child {
        font-weight: 600;
        flex: 0 0 15%;
      }
    }

    @media all and (min-width: 992px) {
      width: 240px;
    }
  }

  @media all and (min-width: 992px) {
    right: 65px;
    bottom: 24px;
    width: 375px;
  }
}
