.rie-detail {
  &__tabs {
    @media all and (min-width: 992px) {
      display: none;
    }
  }

  &__map {
    visibility: hidden;
    height: 0;

    @media all and (min-width: 992px) {
      visibility: visible;
      height: auto;
    }

    .map {
      height: 300px;

      @media all and (min-width: 992px) {
        &__google {
          border-radius: 20px;
        }
      }
    }

    &--visible {
      visibility: visible;
      height: auto;
    }
  }

  &__map-actions {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: var(--white);

    button:disabled {
      cursor: initial;
      opacity: 0.5;
    }

    @media all and (min-width: 992px) {
      padding: 16px 0 0;
      background: unset;
      justify-content: flex-end;
    }
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    padding: 12px;

    @media all and (min-width: 992px) {
      gap: 40px;
      justify-content: space-between;
      padding: 0;
    }

    &-info {
      display: none;

      @media all and (min-width: 992px) {
        display: block;
      }
    }

    p {
      font-size: var(--font-xsmall);
      line-height: 22px;
      margin-bottom: 0;
    }
  }

  &__header-actions {
    display: flex;
    align-items: flex-end;
    gap: 8px;
    flex-wrap: wrap;

    .action-loader {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 55px;
    }
  }

  &__save-message {
    text-align: right;
    padding: 0 12px 12px;

    @media all and (min-width: 992px) {
      margin-top: 10px;
      padding: 0;
    }
  }

  &__content {
    display: block;

    @media all and (min-width: 992px) {
      margin-top: 20px;
      display: flex;
      gap: 24px;
    }
  }

  &__info-blocks {
    @media all and (min-width: 992px) {
      flex: 5;
    }

    @media all and (min-width: 1200px) {
      flex: 4;
    }
  }

  &__info-block {
    &:last-child {
      @media all and (min-width: 992px) {
        margin-bottom: 0;
      }
    }

    @media all and (min-width: 992px) {
      margin-bottom: 16px;
      display: block !important;
    }
  }

  &__accordions {
    padding: 12px;

    @media all and (min-width: 992px) {
      flex: 7;
      padding: 0;
    }

    @media all and (min-width: 1200px) {
      flex: 8;
    }
  }

  &__accordion {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__image-container {
    display: flex;
    flex-direction: row-reverse;

    &--no-slider {
      flex-wrap: wrap;
      gap: 4px;
    }
  }

  &__image-inner-container {
    display: flex;
    flex-direction: column;
  }

  &__image-container-button {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 8px;
  }
}

.monet-modal--image-edit {
  .monet-modal__wrapper {
    width: 440px;
  }
}

.quarantine-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;

  &__wrapper {
    position: fixed;
    z-index: 110;
    top: 50%;
    left: 50%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    box-sizing: border-box;
    width: 315px;
    transition: all 0.3s ease;
    transform: translate(-50%, -50%);
    transform-origin: center;
    opacity: 0;
    border-radius: 7px;
    background: var(--white);
  }

  &__overlay.button {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transition: all 0.2s;
    opacity: 0;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  &__button {
    font-size: var(--font-small);
    min-height: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 24px 16px;
    border-bottom: 3px solid var(--light-grey);
  }

  &__prefix {
    margin-right: 8px;

    > * {
      margin-right: 0;
    }
  }

  &__chapter {
    font-size: var(--font-medium);
    text-transform: capitalize;
  }

  &__close {
    margin-left: auto;
  }

  &__content {
    padding: 16px 16px 24px;
  }

  &__question {
    font-weight: bold;
    display: block;
    margin-bottom: 16px;
  }

  &__assessments {
    display: flex;
    gap: 12px;
  }

  &__textarea {
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
    width: 100%;
    min-height: 100px;
    margin-bottom: 20px;
    padding: 12px 16px;
    resize: none;
    border: 0;
    border-radius: 7px;
  }

  &__footer {
    padding: 24px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    background: var(--light-purple);
  }

  &__actions {
    display: flex;
    justify-content: space-around;
  }

  &__action {
    padding-right: 24px;
    padding-left: 24px;
  }

  &--active {
    pointer-events: all;

    .quarantine-modal__wrapper {
      opacity: 1;
    }

    .quarantine-modal__overlay.button {
      opacity: 1;
    }
  }
}

.safety-status-symbol-wrapper {
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }
}
