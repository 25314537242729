.question {
  font-size: var(--font-small);
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 3px solid var(--light-grey);

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    width: 100%;

    &-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 20px;

      @media all and (min-width: 992px) {
        margin-right: 40px;
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__question {
    margin-right: auto;
    display: flex;

    &--requires-attention {
      font-weight: bold;
    }
  }

  &__question_number {
    margin-right: 8px;
    text-align: right;
    min-width: 30px;
  }

  &__description {
    font-weight: bold;
    max-width: 430px;
    padding-left: 35px;
  }

  &__date {
    font-weight: bold;
  }

  &__assessment {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 40px;
    text-transform: uppercase;
  }

  &__edit {
    height: 40px;
    width: 40px;
    margin-left: 8px;
  }

  &__content {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;

    &--img-only {
      flex-direction: row-reverse;
    }

    &--pdf {
      flex-direction: column;
      gap: 8px;
    }
  }

  &__image-container {
    max-width: 100%;
    text-align: right;
    margin: 0 auto;

    @media all and (min-width: 576px) {
      max-width: 400px;
      min-width: 50%;
      margin: 0 0 0 auto;
    }

    &--no-slider {
      max-width: unset;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row-reverse;
      gap: 4px;
    }

    .image-slider__button {
      img {
        height: auto;
        width: 100%;
      }
    }
  }

  @media all and (max-width: 576px) {
    &__content {
      display: flex;
      flex-direction: column;
    }

    &__image-slider {
      min-width: calc(100% + 64px);
      margin-left: -32px;
    }
  }
}
