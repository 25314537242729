.flyout {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  width: 100vw;

  &__wrapper {
    z-index: 110;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    background: var(--light-purple);
    transform: translateX(100%);
    transition: all 0.3s ease;
    box-sizing: border-box;
    overflow-y: scroll;
    padding: 24px;

    @media all and (min-width: 992px) {
      right: 0;
      width: 450px;
      height: 100vh;
      padding-top: 40px;
    }

    @media all and (max-width: 992px) {
      width: 100vw;
      left: 0;
      bottom: 0;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 24px;
  }

  &__header-content {
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media all and (min-width: 992px) {
      padding-top: 10px;
    }
  }

  &__header-top {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__header-bottom {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__header-logo {
    margin-top: 3px;
  }

  &__header-title {
    margin-bottom: 0;
  }

  &__header-close {
    @media all and (min-width: 992px) {
      width: 85px;
    }
  }

  &__footer {
    margin-top: 24px;
  }

  &__overlay.button {
    z-index: 100;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: all 0.2s;
    border-radius: 0;
  }

  &--active {
    pointer-events: all;

    .flyout__wrapper {
      transform: translateX(0%);
    }

    .flyout__overlay.button {
      opacity: 1;
    }
  }
}
