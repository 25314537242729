// @todo Rework icon colors.
.question-edit-button {
  font-family: var(--content);
  font-size: var(--font-small);
  font-weight: bold;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--sub-icon-size);
  padding: 16px var(--padding) 16px 16px;
  cursor: pointer;
  white-space: nowrap;
  text-transform: uppercase;
  color: var(--black);
  border: 0;
  border-radius: 30px;
  background: var(--light-purple);
  box-shadow: none;

  --sub-icon-size: 34px;
  --padding: calc(var(--sub-icon-size) + 16px);

  &--no-edit {
    cursor: initial;

    --padding: 16px;

    .button-container {
      display: none !important; // Overwrite default.
    }
  }

  .button-container {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    width: var(--sub-icon-size);
    height: var(--sub-icon-size);
    border-radius: 50%;
    background-color: #22303e;

    .inner-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .icon {
      display: flex;
      justify-content: center;
      text-align: center;

      span {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  &--primary {
    color: var(--white);
    background: var(--red);

    .button-container {
      background-color: var(--orange);
    }
  }

  &--transparent {
    min-height: 0;
    padding: 0;
    background: transparent;

    path {
      stroke: var(--black);
    }

    &:focus {
      outline: none;
    }
  }

  &--dark {
    color: var(--white);
    background: var(--black);

    .button-container {
      background-color: #22303e;
    }
  }

  &--orange {
    color: var(--white);
    background: var(--orange-dark);

    .button-container {
      background-color: var(--orange);
    }
  }

  &--yellow {
    color: var(--black);
    background: var(--yellow-dark);

    .button-container {
      background-color: var(--yellow);
    }
  }

  &--red {
    color: var(--white);
    background: var(--red-dark);

    .button-container {
      background-color: var(--red);
    }
  }

  &--green {
    color: var(--white);
    background: var(--green-dark);

    .button-container {
      background-color: var(--green);
    }
  }
}
