.pager-button {
  border: none;
  background-color: transparent;
  padding: 0;
  opacity: 0.25;
  cursor: initial;

  &--active {
    opacity: 1;
    cursor: pointer;
  }
}
