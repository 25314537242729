.input {
  display: flex;
  align-items: stretch;
  background: var(--white);
  border-radius: 7px;

  &__icon {
    padding-left: 24px;
    align-self: center;
  }

  &__input {
    font-size: 16px;
    color: var(--black);
    width: 100%;
    padding: 0 24px;
    height: 55px;
    border-radius: 7px;
    border: 0;

    &:disabled {
      background-color: var(--light-grey);
    }

    &:focus {
      outline: none;
    }
  }

  &--has-error {
    input {
      border: 1px solid var(--red);
    }
  }
}
