// @todo Rework icon colors.
.button {
  font-family: var(--content);
  font-size: var(--font-medium);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 54px;
  padding: 16px 32px;
  cursor: pointer;
  color: var(--black);
  border: 0;
  border-radius: 30px;
  background: var(--light-purple);
  box-shadow: none;
  white-space: nowrap;

  &__label {
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    pointer-events: none;

    &::after {
      font-weight: bold;
      visibility: hidden;
      overflow: hidden;
      height: 0;
      content: attr(data-label);
      user-select: none;
      pointer-events: none;

      @media speech {
        display: none;
      }
    }
  }

  &__icon {
    pointer-events: none;
  }

  &--has-icon {
    justify-content: space-between;
  }

  &--icon-left {
    flex-direction: row-reverse;

    .button__icon {
      margin-right: 8px;
    }
  }

  &--icon-right {
    .button__icon {
      margin-left: 8px;
    }
  }

  &--primary {
    color: var(--white);
    background: var(--orange);
  }

  &--transparent {
    min-height: 0;
    padding: 0;
    background: transparent;

    path {
      stroke: var(--black);
    }

    &:focus {
      outline: none;
    }
  }

  &--dark {
    color: var(--white);
    background: var(--black);
  }

  &--disabled {
    cursor: wait;
    opacity: 0.8;
  }
}
