.rie-data-table {
  .data-table {
    border-radius: 0;

    @media all and (min-width: 992px) {
      .data-table {
        thead {
          display: block;
        }
      }
    }

    @media all and (max-width: 992px) {
      padding: 0;
      background-color: transparent;

      .data-table {
        &__thead {
          display: none;
        }

        &__pager {
          width: 100%;
          gap: 4px;
          flex-direction: column-reverse;

          & > div {
            width: 100%;
            justify-content: center;
            text-align: center;
          }
        }

        &__table-row {
          display: grid;
          grid-template-areas:
            'a a'
            'a a'
            'a a';
          grid-template-columns: 50% 50%;
          margin-bottom: 8px;
          border-radius: 12px;

          &:last-of-type {
            margin-bottom: 12px;
          }

          &:nth-child(even).data-table__table-row {
            background: var(--light-purple);
          }
        }

        &__table-cell {
          padding: 12px;

          &:nth-child(1),
          &:nth-child(2), {
            color: var(--orange);
          }

          &:nth-child(3),
          &:nth-child(4), {
            border-bottom: 1px solid var(--grey);
            border-top: 1px solid var(--grey);
          }
        }
      }
    }
  }
}
