$maps-height-reduction: 185px;

.locations {
  position: relative;

  &__search-bar {
    position: absolute;
    z-index: 20;
    top: 0;
    display: none;

    @media all and (min-width: 992px) {
      position: static;
      display: block;
      margin-bottom: 24px;
    }

    @media all and (max-width: 992px) {
      .search-bar {
        border-radius: unset;
        justify-content: flex-start;

        &__submit {
          justify-content: center;
        }
      }
    }
  }

  &__active {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__header {
    display: none;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    @media all and (min-width: 992px) {
      display: flex;
    }
  }

  &__title {
    margin-bottom: 0;
  }

  &__maps {
    transition: all 0.3s;
    height: calc(100vh - #{$maps-height-reduction});

    @media all and (min-width: 992px) {
      height: 840px;
    }
  }

  &__list {
    transition: all 0.3s;
    padding: 12px;
    box-sizing: border-box;

    @media all and (min-width: 992px) {
      padding: 0;
    }
  }

  &__slider {
    overflow: hidden;

    &--active {
      .slick-track {
        height: auto !important;
      }
    }

    @media all and (min-width: 992px) {
      border-radius: 20px;
    }

    .slick-track {
      display: flex;
      height: calc(100vh - #{$maps-height-reduction});

      @media all and (min-width: 992px) {
        height: 840px;
      }
    }
  }

  &__tabs {
    display: block;
    padding: 0 28px;
    overflow: hidden;

    @media all and (min-width: 992px) {
      display: none;
      margin: 0;
    }
  }

  &__info-block {
    margin: 0 0 10px;
    border-radius: 10px;
    background: var(--white);

    .info-block__item:first-child {
      color: var(--orange);
    }
  }
}
