.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  cursor: pointer;
  border: 0;
  border-radius: 50%;
  background: var(--light-purple);
  box-shadow: none;

  .icon {
    pointer-events: none;

    svg,
    span,
    path {
      pointer-events: none;
    }
  }

  &--transparent {
    height: auto;
    width: auto;
    background: transparent;
  }

  &--transparent-inverted {
    height: auto;
    width: auto;
    background: transparent;
  }

  &--primary {
    color: var(--white);
    background: var(--orange);
  }

  &--primary-inverted {
    color: var(--orange);
    background: var(--white);
  }

  &--dark {
    color: var(--white);
    background: var(--black);
  }
}
