.accordion {
  overflow: hidden;
  border-radius: 8px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    transition: all 0.2s;
    border-bottom: 2px solid transparent;
    outline: none;
    cursor: pointer;
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
  }

  &__number {
    font-weight: bold;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-right: 12px;
    color: var(--white);
    border-radius: 50%;
    background: var(--darker-grey);
  }

  &__toggle {
    transition: all 0.2s;

    &:focus {
      outline: none;
    }
  }

  &__slide-down {
    transition: all 0.4s;
  }

  &__content {
    padding: 12px;

    @media all and (min-width: 992px) {
      padding: 12px 32px 32px;
    }
  }

  &--has-background {
    .accordion__header {
      padding: 16px;
      border-color: var(--white);
      background: var(--white);
      outline: none;
    }

    .accordion__content {
      background: var(--white);
    }
  }

  &--active {
    .accordion__toggle {
      transform: rotate(0.5turn);
    }
  }

  &.accordion--active.accordion--has-background {
    .accordion__header {
      border-color: var(--light-grey);
    }
  }
}
