.marker {
  --safety-color: var(--grey);

  font-size: var(--font-medium);
  position: relative;
  cursor: pointer;

  &-label {
    border: none;
    position: absolute;
    bottom: 4px;
    width: fit-content;
    padding: 12px 8px;
    white-space: nowrap;
    color: var(--white);
    border-radius: 5px;
    background-color: var(--safety-color);
    transform: translateX(-55%);
  }

  &--cluster {
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: var(--monet-blue);
    border-radius: 50%;
    color: white;
    line-height: 40px;
    display: flex;
    transform: translate(-50%, -50%);
    border: none;
  }

  &--color-green {
    --safety-color: var(--green);
  }

  &--color-yellow {
    --safety-color: var(--yellow);
  }

  &--color-orange {
    --safety-color: var(--orange);
  }

  &--color-red {
    --safety-color: var(--red);
  }

  &--location {
    &::after {
      position: absolute;
      left: -4px;
      bottom: 0;
      width: 8px;
      height: 8px;
      content: '';
      transform: translate(-50%) rotate(45deg);
      background-color: var(--safety-color);
      pointer-events: none;
    }
  }
}
