.status-symbol {
  margin-right: 12px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  overflow: hidden;
  background: var(--darker-grey);
  color: var(--white);
  border-radius: 50%;

  // @todo Add missing colors.
  &--grey {
    background: var(--dark-grey);
  }

  &--green {
    background: var(--green);
  }

  &--yellow {
    background: var(--yellow);
  }

  &--orange {
    background: var(--orange);
  }

  &--red {
    background: var(--red);
  }

  &--small {
    width: 20px;
    height: 20px;
  }
}
