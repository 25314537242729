@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
  // Common colors
  --black: #000000;
  --white: #ffffff;
  --off-white: #e5e5e5;

  // Grey tones
  --light-grey: #f4f4f6;
  --grey: #cfcfe4;
  --dark-grey: #bfbfbf;
  --darker-grey: #757575;

  // Brand colors
  --light-purple: #f5f5fd;
  --lighter-purple: #e6e6f2;
  --purple: #524096;
  --lavender: #90818f;
  --orange: #de5307;
  --red: #bb1e10;
  --yellow: #faca30;
  --green: #237f52;
  --orange-dark: #ff9659;
  --red-dark: #c03534;
  --yellow-dark: #ffce69;
  --green-dark: #109e85;
  --monet-blue: #322173;

  // Fonts
  --headers: 'Open Sans', sans-serif;
  --content: 'Open Sans', sans-serif;

  // Typography
  --font-xsmall: 12px;
  --font-small: 14px;
  --font-medium: 16px;
  --font-big: 18px;
  --font-title: 22px;

  // @todo Replace with global sass variables later, because we can't use CSS variables in media queries.
  // Breakpoints
  --sm: 567px;
  --md: 768px;
  --lg: 992px;
  --xl: 1200px;
  --xxl: 1400px;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: var(--font-medium);
  line-height: 24px;
  padding: 0;
  background: var(--lighter-purple);
  margin: 0;

  @media all and (min-width: 992px) {
    padding: 40px 80px;
  }
}

p {
  margin: 0 0 16px;
}

*:focus {
  outline: 2px solid var(--orange);
}
