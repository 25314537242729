.search-bar {
  height: 100%;
  padding: 24px;
  border-radius: 20px;
  background: var(--light-purple);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;

  @media all and (min-width: 992px) {
    border-radius: 20px;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__info {
    width: 100%;

    p {
      font-size: var(--font-xsmall);
    }
  }

  &__filters {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    > div {
      width: 100%;
    }

    button {
      margin: 40px 0 0;
    }

    @media all and (min-width: 992px) {
      flex-direction: row;
      flex-wrap: wrap;

      > div {
        width: 300px;
      }

      button {
        margin: 0;
      }
    }
  }

  &__submit {
    flex-basis: 54px;
    display: flex;
    justify-content: center;

    @media all and (min-width: 992px) {
      flex-basis: 150px;
      flex-direction: column;
      text-align: right;
      justify-content: flex-end;

      button {
        height: 54px;
      }
    }
  }

  &__search-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 12px;

    @media all and (min-width: 992px) {
      flex-direction: row;
    }
  }
}
