.toggle {
  font-family: var(--content);
  position: relative;
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
  border: 0;
  border-radius: 30px;
  background: var(--grey);
  box-shadow: none;
  gap: 8px;

  &__option {
    z-index: 10;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    transition: color 0.3s ease;
    font-family: var(--content);
    font-size: var(--font-medium);

    &::after {
      font-weight: bold;
      visibility: hidden;
      overflow: hidden;
      height: 0;
      content: attr(data-label);
      user-select: none;
      pointer-events: none;

      @media speech {
        display: none;
      }
    }

    &--off {
      font-weight: bold;
      color: var(--white);
    }
  }

  &__switch {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    display: block;
    width: 50%;
    height: 100%;
    transition: all 0.3s ease;
    border-radius: 30px;
    background: var(--orange);
  }

  &--active {
    .toggle__option {
      &--off {
        font-weight: normal;
        color: var(--black);
      }

      &--on {
        font-weight: bold;
        color: var(--white);
      }
    }
  }
}
