.image-slider__image-container {
  position: relative;

  .icon-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.image-slider {
  max-width: 400px;

  &__slick-slider {
    position: relative;
  }

  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    z-index: 14;
    width: 32px;
    height: 32px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: #000000;
    border-radius: 50%;

    &::before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      float: left;
      margin: 7px 12px;
    }
  }

  .slick-prev {
    left: 49px;

    &::before {
      background: url('chevron-right.svg') no-repeat;
      transform: rotate(0.5turn);
      margin: 0;
    }
  }

  .slick-next {
    right: 38px;

    &::before {
      background: url('chevron-right.svg') no-repeat;
    }
  }

  .slick-slide {
    overflow: hidden;

    > div {
      margin: 0 8px;
      height: 200px;
    }

    img {
      object-fit: cover;
      object-position: center;
    }
  }

  .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0 60px;

    .slick-track {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      margin-left: auto;
      margin-right: auto;

      .slick-slide {
        display: block;
        float: left;
        height: 100%;
        min-height: 200px;

        img {
          opacity: 0.5;
        }

        > div {
          // margin: 0 10px;
        }

        .image-slider__image-container {
          text-align: center;
        }

        &.slick-active {
          img {
            opacity: 1;
          }
        }

        &[aria-hidden='true'] {
          .icon-button {
            display: none;
          }
        }
      }

      .slick-active {
        // background-color: darkgray;
      }
    }
  }

  .slick-dots {
    display: flex !important;
    justify-content: center;
    padding: 0;
    margin-top: 8px;
    list-style-type: none;
    align-items: center;
    height: 28px;
    flex-wrap: wrap;
    gap: 5px;

    li {
      margin: 0 0.25rem;
    }

    button {
      display: block;
      width: 8px;
      min-height: 8px;
      height: 8px;
      padding: 0;
      border: none;
      border-radius: 100%;
      background-color: #c4c4c4;
      text-indent: -9999px;
    }

    li.slick-active button {
      background-color: #000000;
    }
  }

  &__button {
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
  }
}
