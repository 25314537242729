.menu {
  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 22px;
    color: var(--black);
    min-height: 50px;

    .icon {
      margin-right: 12px;
    }

    &--disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    &--hide-mobile {
      @media all and (max-width: 992px) {
        display: none;
      }
    }
  }

  &__item {
    position: relative;

    &--hide-mobile {
      @media all and (max-width: 992px) {
        display: none;
      }
    }
  }

  &__item-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .lds-ellipsis {
      margin: 0 auto;
    }
  }

  &__sub-link {
    display: block;
    text-decoration: none;
    color: var(--darker-grey);
    line-height: 30px;
  }

  &__footer {
    display: flex;
    gap: 16px;
  }
}
