.info-block {
  background: var(--white);

  @media all and (min-width: 992px) {
    padding: 16px 44px;
    border-radius: 20px;
    background: var(--light-purple);
  }

  &__title {
    display: none;

    @media all and (min-width: 992px) {
      display: block;
    }
  }

  &__item {
    font-family: var(--content);
    font-size: var(--font-medium);
    display: grid;
    padding: 12px;
    border-bottom: 1px solid var(--lighter-purple);
    grid-template-columns: 140px 1fr;
    grid-column-gap: 20px;

    @media all and (min-width: 1200px) {
      gap: 40px;
    }

    &:first-child {
      padding-top: 12px;

      @media all and (min-width: 992px) {
        padding-top: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
    }

    @media all and (min-width: 992px) {
      margin-bottom: 12px;
      padding: 0;
      border-bottom: 0;
    }
  }

  &__validation-message {
    font-size: 14px;
    padding-left: 8px;
    color: red;
  }

  &__label {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media all and (min-width: 992px) {
      font-weight: 600;
    }
  }

  &--invisible {
    display: none;
  }

  $input-height: 35px;

  &__input input {
    height: $input-height;

    @media all and (max-width: 992px) {
      border: 1px solid var(--grey);
    }
  }

  &__plain {
    margin: 0;
  }

  .input-invalid input {
    border: 1px solid red;
  }

  .react-datepicker-wrapper .react-datepicker__input-container input {
    height: $input-height;
    border: 1px solid var(--grey);
    width: 100%;

    @media all and (min-width: 992px) {
      border: 0;
    }
  }
}
