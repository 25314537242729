.react-select__control {
  background: var(--white);
  font-size: 16px;
  color: var(--black);
  width: 100% !important; // Overwrite the default Select css.
  border: 0 !important; // Overwrite the default Select css.
  padding: 0 24px !important; // Overwrite the default Select css.
  border-radius: 7px !important; // Overwrite the default Select css.
  min-height: 60px !important; // Overwrite the default Select css.
  height: auto !important; // Overwrite the default Select css.

  &[aria-expanded='true'] {
    outline: 2px solid var(--orange);
  }

  &:focus {
    outline: none;
  }

  input {
    font-size: 16px;
    color: var(--black);
  }
}

.react-select__value-container {
  padding: 2px 0 !important; // Overwrite the default Select css.
}
