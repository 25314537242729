.tab {
  display: flex;
  align-items: center;
  padding: 24px 0;
  position: relative;
  overflow: hidden;
  min-height: 75px;
  box-sizing: border-box;

  &__button {
    color: var(--white);
    line-height: 26px;
    font-weight: normal;
    min-height: 0;
    text-transform: uppercase;
  }

  &--active {
    &::after {
      content: '';
      width: 20px;
      display: block;
      height: 20px;
      position: absolute;
      top: calc(100% - 10px);
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      background: var(--white);
    }

    .tab__button {
      font-weight: bold;
    }
  }
}
