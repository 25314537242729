.tabs {
  background: var(--purple);
  position: relative;
  padding: 0 12px;

  &::after {
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -25vw;
    content: '';
    pointer-events: none;
    border-left: 125vw solid var(--purple);
    box-shadow: 125vw 0 0 var(--purple);
  }

  &__items {
    display: flex;
    align-items: stretch;
    gap: 24px;
  }

  &__tab:last-child {
    margin-left: auto;
  }
}
