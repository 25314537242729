.loader-container {
  display: flex;
  justify-content: space-around;
  margin-top: 200px;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

@media all and (max-width: 992px) {
  .page {
    padding: 0 16px 16px;

    .page-header {
      margin-bottom: 0;
    }

    .search-bar {
      padding: 12px;
      margin-bottom: 12px;

      .search-bar__filters button {
        margin-top: 0;
      }
    }
  }
}

@media all and (min-width: 992px) {
  .page {
    .search-bar {
      margin-bottom: 24px;
    }
  }
}

.page-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 20px;

  .page-header__actions {
    display: flex;
    flex-direction: column-reverse;

    .button {
      flex-grow: 0;
      height: 54px;
    }
  }
}

.react-datepicker-wrapper {
  .react-datepicker__input-container {
    display: flex;
    flex-direction: row;

    input {
      flex-grow: 1;
      font-size: 16px;
      color: var(--black);
      border: 0;
      padding: 0 24px;
      height: 55px;
      border-radius: 7px;

      &:focus {
        outline: none;
      }
    }
  }
}

.react-select__control.react-select__control {
  min-height: 55px !important;
}

.prevent-scroll {
  overflow: hidden;
}
